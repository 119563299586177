<template>
  <div class="categories">
    <Navbar/>  
    <div class="container">
        <CategorieTitle>
            <template #maintitle>
                Web/digital школы
            </template>
        </CategorieTitle>

        <div class="row school-row" >

            <SchoolCard :url="'#'" :background="'image-hetic'">
                <template #title>
                    HETIC
                </template>
                <template #subtitle>
                    первая школа во Франции, которая предложила 5-летнюю программу обучения, посвященную карьере в Интернете. Выпускники стали консультантами по аналитике, руководителями проектов электронного маркетинга, веб-дизайнерами и директорами по цифровому искусству.
                </template>
            </SchoolCard>

            <SchoolCard :url="'#'" :background="'image-digital-campus'">
                <template #title>
                    Digital Campus
                </template>
                <template #subtitle>
                    школа,  специализирующаяся на цифровом дизайне, предлагает программы бакалавриата и магистратуры с упором на производство мультимедиа и цифровую стратегию.
                </template>
            </SchoolCard>
        </div>

    </div>
  </div>
</template>

<script>

import Navbar from '@/components/common/Navbar.vue'
import SchoolCard from '@/components/categories/slots/SchoolCard.vue'
import CategorieTitle from '@/components/categories/slots/CategorieTitle.vue'

export default {
  name: 'WebSchools',
  components: {
    Navbar,
    SchoolCard,
    CategorieTitle
  }
}
</script>
